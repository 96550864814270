/**
 * Polyfill for browsers that do not support Element.closest(),
 * but carry support for document.querySelectorAll().
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
 */
(function() {
    if (window.Element && !Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i,
                el = this;
            do {
                i = matches.length;
                while (--i >= 0 && matches.item(i) !== el) {};
            } while ((i < 0) && (el = el.parentElement));
            return el;
        };
    }
})();

/**
 * Polyfill to add support for creating custom events in IE9–11.
 *
 * @link https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
 */
(function() {
    if (typeof window.CustomEvent === "function")
        return false; //If not IE

    function CustomEvent(event, params) {
        params = params || {
            bubbles: false,
            cancelable: false,
            detail: undefined
        };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
})();

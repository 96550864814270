(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

require('./helpers/polyfills');

window.EmComponents = {
	/**
  * Placeholder for components that should be initialized.
  * @type {Object}
  */
	components: {},

	/**
  * Add events to hook into at various points in the setup process.
  */
	dispatchEvents: function dispatchEvents() {
		/**
   * Triggered before components are added.
   */
		var setupEvent = new CustomEvent('EmComponents:ready');
		window.dispatchEvent(setupEvent);

		/**
   * Triggered during initialization.
   */
		var initEvent = new CustomEvent('EmComponents:init');
		window.dispatchEvent(initEvent);

		/**
   * Triggered after components are initialized.
   */
		var loadedEvent = new CustomEvent('EmComponents:loaded');
		window.dispatchEvent(loadedEvent);
	},


	/**
  * Add a new component to EmComponents.components.
  * @param  {String} componentName    (Required) The name of the component.
  * @param  {Class} 	componentClass   (Required) Reference to the component's class.
  * @param  {Object} componentOptions (Optional) Object of component options.
  */
	addComponent: function addComponent(componentName, componentClass, componentOptions) {
		this.components[componentName] = {
			name: componentName,
			class: componentClass,
			options: componentOptions
		};
	},


	/**
  * Remove the component from EmComponents.components.
  * @param  {String} componentName Name of the component to remove.
  */
	removeComponent: function removeComponent(componentName) {
		delete this.components[componentName];
	},


	/**
  * Created a new instance of the supplied component.
  * @param  {Object} component (Required) Object containing name, class, and options.
  */
	initComponent: function initComponent(component) {
		this.components[component.name].instance = new component.class(component.options);
	},


	/**
  * Global init funciton that initializes all components in EmComponents.components.
  */
	init: function init() {
		for (var component in this.components) {
			this.initComponent(this.components[component]);
		}
	}
};

/**
 * Setup custom events, so that modules can hook in at the right time.
 */
window.addEventListener('DOMContentLoaded', window.EmComponents.dispatchEvents);

/**
 * Initialize all registered components.
 */
window.addEventListener('EmComponents:init', function (e) {
	window.EmComponents.init();
});

},{"./helpers/polyfills":2}],2:[function(require,module,exports){
"use strict";

/**
 * Polyfill for browsers that do not support Element.closest(),
 * but carry support for document.querySelectorAll().
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
 */
(function () {
    if (window.Element && !Element.prototype.closest) {
        Element.prototype.closest = function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i,
                el = this;
            do {
                i = matches.length;
                while (--i >= 0 && matches.item(i) !== el) {};
            } while (i < 0 && (el = el.parentElement));
            return el;
        };
    }
})();

/**
 * Polyfill to add support for creating custom events in IE9–11.
 *
 * @link https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
 */
(function () {
    if (typeof window.CustomEvent === "function") return false; //If not IE

    function CustomEvent(event, params) {
        params = params || {
            bubbles: false,
            cancelable: false,
            detail: undefined
        };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
})();

},{}]},{},[1])

